<template>
  <div>
    <en-table-layout tips :toolbar="false" :tableData="tableData.data" :loading="loading">
      <!-- 搜索条件 -->
      <div slot="tips" class="inner-toolbar">
        <div class="toolbar-btns">
          <!--商户类型 -->
          <div class="conditions">
            <div>商户类型：</div>
            <el-select
              class="ipt-default"
              style="width:100px"
              v-model="advancedForm.shop_status"
              clearable
            >
              <el-option value label="全部"></el-option>
              <el-option value="2" label="个人"></el-option>
              <el-option value="1" label="企业"></el-option>
            </el-select>
            <!-- <div>状态：</div>
            <el-select
              class="ipt-default"
              style="width:100px"
              v-model="advancedForm.shop_disable"
              clearable
            >
              <el-option value label="全部"></el-option>
              <el-option value="APPLYING" label="待审核"></el-option>
              <el-option value="OPEN" label="已通过"></el-option>
              <el-option value="REFUSED" label="已拒绝"></el-option>
            </el-select>-->
          </div>
          <!--关键字-->
          <div class="conditions">
            <div>关键字选择：</div>
            <el-select class="ipt-default" v-model="advancedForm.keyword_type" clearable>
              <el-option value="shop_no" label="商户编号"></el-option>
              <el-option value="shop_name" label="商户名称"></el-option>
              <el-option value="link_name" label="联系人姓名"></el-option>
              <el-option value="link_phone" label="联系人手机号查询"></el-option>
            </el-select>
            <el-input
              class="ipt-default"
              style="width: 200px"
              v-model="advancedForm.keyword"
              placeholder="请输入关键字进行搜索"
              clearable
            ></el-input>
            <el-button class="btn-default" @click="searchEvent">筛选</el-button>
          </div>
        </div>
      </div>
      <!-- 表格数据 -->
      <template slot="table-columns">
        <el-table-column prop="shop_no" label="商户编号" width="180" />
        <el-table-column prop="shop_name" label="商户名称" width="180" />

        <el-table-column prop="link_name" label="联系人姓名" width="180" />
        <el-table-column prop="link_phone" label="联系人手机号" width="180" />
        <el-table-column prop="shop_status" label="类型" width="180">
          <template slot-scope="scope">{{ scope.row.shop_status=="1"?'企业':'个人' }}</template>
        </el-table-column>
        <!-- <el-table-column prop="link_weixin" label="微信号" width="180" /> -->
        <!-- <el-table-column prop="link_email" label="电子邮箱" width="180" /> -->

        <el-table-column label="创建日期" width="180">
          <template slot-scope="scope">{{ scope.row.create_date | unixToDate }}</template>
        </el-table-column>
        <el-table-column prop="shop_disable" label="状态" width="180">
          <template
            slot-scope="scope"
          >{{ scope.row.shop_disable==='APPLYING'?'待审核':scope.row.shop_disable==='OPEN'?'已通过':'已拒绝' }}</template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" width="280">
          <template slot-scope="scope">
            <!-- <el-button size="mini" type="text" @click="handleDelete(scope.row)">删除</el-button> -->
            <el-button size="mini" type="text" @click="handleEdit(scope.row)">审核</el-button>
            <el-button size="mini" type="text" @click="handleInfo(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </template>
      <!-- 表格分页 -->
      <el-pagination
        v-if="tableData"
        slot="pagination"
        @size-change="handlePageSizeChange"
        @current-change="handlePageCurrentChange"
        :current-page="tableData.page_no"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="tableData.page_size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="tableData.data_total"
      ></el-pagination>
    </en-table-layout>

    <!--审核操作 dialog-->
    <el-dialog
      title="审核操作"
      :visible.sync="dialogVisible"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form :model="editForm" :rules="MessageVisibleRules" ref="editForm" label-width="100px">
        <!--审核-->
        <el-form-item label="审核" prop="pass">
          <el-radio v-model="editForm.pass" :label="1">通过</el-radio>
          <el-radio v-model="editForm.pass" :label="0">拒绝</el-radio>
        </el-form-item>
        <!--原因-->
        <el-form-item label="原因" prop="audit_reason">
          <el-input type="textarea" v-model="editForm.audit_reason"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button class="btn-default-dialog-no" @click="dialogVisible = false">取 消</el-button>
        <el-button class="btn-default-dialog-yes" @click="submitForm()">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as API_Member from "@/api/member";
import * as API_Shop from "@/api/shop";
// import { RegExp } from "@/libs/common/utils";
export default {
  name: "memberExamine",

  data() {
    return {
      /** 列表loading状态 */
      loading: false,

      /** 列表参数 */
      params: {
        page_no: 1,
        page_size: 10,
        shop_disable: "APPLYING", // 状态
      },

      /** 列表数据 */
      tableData: "",

      /** 高级搜索数据 */
      advancedForm: {
        shop_type: "", // 商户类型
        keyword_type: "", // 根据关键字
        keyword: "", // 根据关键字搜索
      },

      /** 审核数据 */
      dialogVisible: false,
      editForm: {},
      MessageVisibleRules: {
        pass: [{ required: true, message: "请选择状态", trigger: "blur" }],
        audit_reason: [
          { required: false, message: "请填写原因", trigger: "blur" },
          {
            min: 1,
            max: 200,
            message: "长度在 1 到 200 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  // filters: {
  //   secrecyMobile(mobile) {
  //     mobile = String(mobile);
  //     if (!/\d{11}/.test(mobile)) {
  //       return mobile;
  //     }
  //     return mobile;
  //   }
  // },
  watch: {
    "editForm.pass": function (newVal) {
      this.MessageVisibleRules.audit_reason[0].required = newVal === 0;
    },
  },
  mounted() {
    this.GET_List();
  },
  methods: {
    handleInfo(row) {
      this.$router.push(`/merchant/merchant-apply-detail/${row.shop_id}`);
    },
    /** 审核保存 */
    submitForm() {
      this.$refs["editForm"].validate((valid) => {
        if (valid) {
          const params = this.MixinClone(this.editForm);
          var obj = {
            shop_id: params.shop_id,
            pass: params.pass,
            audit_reason: params.audit_reason,
          };
          API_Shop.editAuthShop(params.shop_id, obj)
            .then((res) => {
              this.$message.success("审核成功！");
              this.dialogVisible = false;
              this.GET_List();
            })
            .catch((_) => {});
        } else {
          this.$message.error("表单填写有误，请检查！");
          return false;
        }
      });
    },
    /** 删除 */
    handleDelete(index, row) {
      this.$confirm("确认要删除该商户的审核信息吗？", "提示", {
        type: "warning",
      })
        .then(() => {
          API_Member.deleteMember(row.member_id).then(() => {
            this.$message.success("删除成功！");
            this.GET_MemberList();
          });
        })
        .catch(() => {});
    },
    /** 审核 */
    handleEdit(row) {
      /** id */
      this.editForm = this.MixinClone(row);
      this.editForm.audit_reason = "";
      this.dialogVisible = true;
    },
    /** 筛选 */
    searchEvent() {
      delete this.params["shop_no"];
      delete this.params["shop_name"];
      delete this.params["link_name"];
      delete this.params["link_phone"];
      this.params.page_no = 1;
      this.params[this.advancedForm.keyword_type] = this.advancedForm.keyword;
      this.params.shop_status = this.advancedForm.shop_status;
      this.GET_List();
    },
    /** 分页大小发生改变 */
    handlePageSizeChange(size) {
      this.params.page_size = size;
      this.GET_List();
    },

    /** 分页页数发生改变 */
    handlePageCurrentChange(page) {
      this.params.page_no = page;
      this.GET_List();
    },
    /** 获取列表 */
    GET_List() {
      this.loading = true;
      const { params } = this;
      Object.keys(params).forEach((key) => {
        if (!params[key] && params[key] !== 0) {
          delete params[key];
        }
      });
      API_Shop.getShopList(params)
        .then((response) => {
          this.loading = false;
          this.tableData = response;
        })
        .catch(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style type="text/scss" lang="scss" scoped>
.show-pwd {
  position: absolute;
  top: 0;
  right: 10px;
  cursor: pointer;
}

.el-date-editor.el-input {
  width: 180px;
}

::v-deep .form-item-sex .el-form-item__content {
  width: 180px;
}

::v-deep .form-item-region .el-form-item__content {
  min-width: 180px;
}
::v-deep .el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 30px;
}
div.toolbar-btns {
  display: contents;
}
</style>
